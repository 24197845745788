import React from "react";


import ButtonIcon from "../../components/ButtonIcon/ButtonIcon";
import Page from "../../components/Page/Page";
import PageTitle from "../../components/PageTitle/PageTitle";
import Container from "../../components/Container/Container";

import styles from "./Aluno.module.scss";

const Volunteer = () => {
    return (
        <Page>
            <PageTitle
                title="Venha estudar conosco!"
                height="250px"
            />

            <div className={styles.container}>
                <div className={styles.container2}>
                    <div className={styles.title}>
                        <h1 className="mt-5">Quer estudar no projeto ALES?</h1>
                    </div>

                    <div className={styles.description}>
                        <p>
                        Deseja participar do projeto? Preencha o formulário de interesse disponível no final desta página.
                        </p>

                        <p>
                            <strong><big>Por que estudar no ALES?</big></strong>
                            <p></p>
                            <ul>
                                <li>Aprender conteúdos diferentes dos oferecidos nas escolas;</li>
                                <li>Descobrir novas oportunidades;</li>
                                <li>Entrar em contato com estudantes universitários;</li>
                                <li>Ampliar a visão de mundo através de um ensino mais prático;</li>
                            </ul>
                        </p>

                        <p>
                            <strong><big>Quais são as aulas?</big></strong>
                            <p></p>
                            <ul>
                                <li><strong>CIÊNCIAS:</strong> Buscamos atiçar a curiosidade natural que todos nós temos para entender o funcionamento da natureza e para enxergar como a biologia, a física e a química estão presentes no nosso dia-a-dia.</li>
                                <li><strong>PROGRAMAÇÃO:</strong> Nosso objetivo em programação é estimular os alunos a enfrentar novos desafios numa matéria que nem sempre é oferecida no ensino comum. Onde podemos desenvolver jogos e aplicações de uma forma divertida e prática, abordando desde os conceitos mais básicos da programação.</li>
                            </ul>
                        </p>

                        <p>
                        Venha desbravar diferentes aprendizados com a gente! Você pode se inscrever em quantas disciplinas quiser! Clique no link abaixo e preencha o formulário. Entraremos em contato com você assim que preencher o formulário. Estamos te esperando!
                        </p>

                        <div className={styles.buttons}>
                            <a
                                className={styles.button}
                                href="https://docs.google.com/forms/d/e/1FAIpQLSdSP4zNLBaIBRQH2NKyqvxUCVqBsa18GZBw_w1oIeItdPCqnw/viewform"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ButtonIcon text="Formulário de Inscrição" kind="yellow" icon="far fa-file-alt" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Volunteer;