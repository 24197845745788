const items = [
  {
    id: 2,
    text: "projetoales@gmail.com",
    icon: "fa fa-envelope-o",
    to: "mailto:projetoales@gmail.com",
  },
  {
    id: 3,
    text: "@projetoales",
    icon: "fa fa-instagram",
    to: "https://www.instagram.com/projetoales/",
  },
];

export default items;
