import React from "react";
import Container from "../../../../components/Container/Container";
import ButtonIcon from "../../../../components/ButtonIcon/ButtonIcon";
import Alessauro from "../../../../assets/logos/alessauro.svg";
import styles from "./SubscriptionVolunteers.module.scss";
import {RiErrorWarningFill} from 'react-icons/ri'

const SubscriptionVolunteers = () => {
  return (
    <div className={styles.container} id="recrutamento">
      <Container>
        <div className={styles.title}>
          <h3><b>As inscrições para voluntáries e alunes estão abertas!</b></h3>
        </div>
        <div className={styles.subtitle}>
          <h4>Curtiu nosso projeto? Então venha fazer parte do ALES</h4>
        </div>
        <div className={styles.description}>
          <p>
            Se você quer trabalhar em um projeto social sem fins lucrativos: o ALES está com vagas para voluntaries. No Ales você pode atuar em uma das duas áreas: Administrativo ou Ensino, na qual você pode participar de um dos times (confira os times logo abaixo). 
            Neste semestre de 2023 teremos aulas presenciais no Instituto de Computação da Unicamp. As inscrições vão do dia 24/02 até 10/03. 
            Então se você se identificou com o projeto e gostaria de participar preencha o formulário linkado abaixo. (ATENÇÃO: NÃO PERCA O PRAZO DE INSCRIÇÃO!!!)
          </p>
        </div>
        <div className={styles.link}>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSez9d-mhq6ykLekogL4oUNr3_r5PaOSYg8rlssiG1jNeTt9Zw/viewform">Link do Formulário para Voluntáries</a>
        </div>
        <div className={styles.description}>
          <p>
            Se você quer estudar programação ou ciências (Química, Física, Biologia) no 1º semestre de 2023: o ALES está com vagas abertas para alunes. O prazo para as inscrições é de 27/02 até 09/03. As aulas serão ministradas no Instituto de Computação da Unciamp. Não perca essa oportunidade! Chame seus amigues e participe do projeto!
            Qualquer dúvida entre em contato com a gente pelo Instagram ou Email. Venha aprender com a gente! O link para o preenchimento do formulário está logo abaixo. (ATENÇÃO: NÃO PERCA O PRAZO DE INSCRIÇÃO!!!)
          </p>
        </div>
        <div className={styles.link}>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdSP4zNLBaIBRQH2NKyqvxUCVqBsa18GZBw_w1oIeItdPCqnw/viewform">Link do Formulário para Alunes</a>
        </div>
        <div className={styles.line}></div>
        <div className={styles.title}>
          <h3>
            <b>Áreas para voluntáries atuarem:</b>
          </h3>
        </div>
        <div className={styles.description}>

<p><b>Administrativo</b></p>
          <div className={styles.table}>
            <table>
              <tr>
                <td>Comunicação</td>
                <td></td>
                <td>Financeiro</td>
                <td></td>
                <td>Pessoas</td>
                <td></td>
                <td>TECH</td>
               
              </tr>
 </table>
</div>

  <p><b>Ensino</b></p>
     <div className={styles.table}>
    <table>
              <tr>
                <td>Programação</td>
                <td></td>
                <td>Ciências</td>
              </tr>
             
            </table>
          </div>

          </div>
        </Container >

{/*
<div className={styles.buttons}>
            <a
              className={styles.button}
              href="https://drive.google.com/file/d/1TAZaFTOWkym_cJJax4Jf2mfcLUrC0WJs/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonIcon text="Edital" kind="secondary" icon="far fa-file-alt" />
            </a>
          </div>          

<p>
            As inscrições vão até <b>28/01</b> e podem ser feitas pelo formulário a seguir:
            </p>

          <div className={styles.buttons}>
            <a
              className={styles.button}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfCtFWbTMnEreqQ61mImHAmSoLH5euHQfrlWWCOtsYELt89Tw/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonIcon text="Inscrição" kind="secondary" icon="far fa-file-alt" />
            </a>
          </div>


        
        <div className={styles.info}>
          <br></br>
          <p>Todo o processo ocorrerá de forma remota, assim como as atividades realizadas ao longo do semestre. <br></br>
          </p>
        </div>
      
    
  */}
   </div >
  )
}
export default SubscriptionVolunteers;
