const items = [
  { id: 1, link: "https://www.facebook.com/projetoales", icon: "fab fa-facebook-f" },
  { id: 2, link: "https://www.instagram.com/projetoales/", icon: "fab fa-instagram" },
  {
    id: 3,
    link: "https://www.youtube.com/channel/UCXL_iYkkNcNpWDE6inM_LTg",
    icon: "fab fa-youtube",
  },
  { id: 4, link: "https://github.com/Projeto-ALES", icon: "fab fa-github-alt" },
];

export default items;
