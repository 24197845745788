import React from "react";

import ButtonIcon from "../../components/ButtonIcon/ButtonIcon";
import Page from "../../components/Page/Page";
import PageTitle from "../../components/PageTitle/PageTitle";

import styles from "./Volunteer.module.scss";

const Volunteer = () => {
    return (

        <Page>
            <PageTitle
                title="Junte-se a nós na democratização do conhecimento, faça a diferença!"
                height="250px"
            />

            <div className={styles.container}>
                <div className={styles.container2}>
                    <div className={styles.title}>
                        <h1 className="mt-5">Gostou do ALES e deseja voluntariar-se?</h1>
                    </div>

                    <div className={styles.description}>
                        <p>
                            Nosso processo seletivo para voluntáries está ABERTO entre os dias 24/02 e 10/03 de 2023. Faça sua inscrição!!!!!
                        </p>

                        <p>
                            <strong><big>Por que se voluntariar no ALES?</big></strong>
                            <p></p>
                            <ul>
                                <li>Conhecer pessoas e criar laços com pessoas engajadas em causas sociais;</li>
                                <li>Desenvolver habilidades importantes para o mercado de trabalho;</li>
                                <li>Agregar seu currículo;</li>
                                <li>Trocas de Cultura;</li>
                                <li>Aperfeiçoamento dos conhecimentos acadêmicos;</li>
                            </ul>
                        </p>
                </div>

                    <div className={styles.description}>

                    <p><strong><big>Quais são as áreas onde posso atuar como voluntário?</big></strong></p>
                        <p>O projeto ALES é dividido em duas grandes áreas:</p>
                        <p>
                            <strong>ENSINO: </strong> área responsável pelas aulas semanais do projeto, que nesse primeiro semestre de 2023 vão acontecer presencialmente no IC (Instituto de Computação da Unicamp). Se você tem vontade de lecionar e estar diretamente envolvido com as aulas, essa é a área para você.  Cada disciplina possui diversos professores, que se revezam ao longo do semestre. Atualmente, as matérias que fazem parte da grade do ALES são: Ciências, Matemática e Programação.
                        </p>

                        <p>
                            <strong>ADM:</strong> é a responsável pelos bastidores do projeto e é dividida em 5 áreas. Algumas das tarefas/atribuições de cada área são:
                            <ul>
                                <li><strong>Comunicação:</strong> divulgação do projeto, gestão das mídias sociais, engajamento dos alunos por meio de posts...</li>
                                <li><strong>Financeiro:</strong> gestão do fluxo de caixa, controle de doações, planejamento orçamental...</li>
                                <li><strong>Pessoas:</strong> gestão da entrada, saída e desempenho dos voluntários, realização de dinâmicas do time e gerais para engajar voluntários, envio e gestão de feedbacks...</li>
                                <li><strong>Tech:</strong> gestão e edição do site, análise de dados, resolução de problemas de caráter tecnológico...</li>
                            </ul>
                        </p>

                        <p>
                            Você pode escolher uma das áreas ou, se quiser, pode se inscrever nas duas, sem nenhum problema!
                        </p>

                        <p>
                            Venha fazer parte do nosso time! Clique no link abaixo e preencha o formulário. Assim que você preencher entraremos em contato com você!
                        </p>

                        <div className={styles.buttons}>
                            <a
                                className={styles.button}
                                href="https://docs.google.com/forms/d/e/1FAIpQLSez9d-mhq6ykLekogL4oUNr3_r5PaOSYg8rlssiG1jNeTt9Zw/viewform"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ButtonIcon text="Formulário de Inscrição!" kind="yellow" icon="far fa-file-alt" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </Page >
    );
};

export default Volunteer;